import SelectMenu from '@/components/basic/SelectMenu/SelectMenu';
import { useAuthenticated } from '@gettactic/hooks';
import { useMemo } from 'react';

type Props = {
  className?: string;
  onChange: (ids: string[]) => void;
  offices?: string[];
  applyOnClick?: boolean;
  maxAllowedOffices?: number;
  allowAll?: boolean;
};

export const SelectOffices = ({
  onChange,
  offices = [],
  className,
  applyOnClick,
  maxAllowedOffices,
  allowAll = true
}: Props) => {
  const {
    userContext: { authenticatedUser }
  } = useAuthenticated();
  const allOffices = authenticatedUser.offices;
  const options = useMemo(() => {
    const elements = (allOffices ? allOffices.offices : []).concat();
    const options = elements.map((x) => ({ label: x.name, value: x.id }));
    options.sort((a, b) => a.label.localeCompare(b.label));
    return options;
  }, [allOffices]);

  return (
    <>
      {allOffices ? (
        <SelectMenu
          className={className}
          applyOnClick={applyOnClick}
          nonePlaceholder="Select Offices"
          allPlaceholder="All Offices"
          options={options}
          values={offices}
          onChange={onChange}
          maxAllowed={maxAllowedOffices}
          allowAll={allowAll}
        />
      ) : null}
    </>
  );
};
