import { classNames } from '@gettactic/helpers/src/css/classNames';
import { IoPersonSharp as ProfileDefault } from 'react-icons/io5';
import { IMAGE_LAYOUTS, imageUrl } from '../../@v2/lib/utils';

export enum AVATAR_SIZES {
  NONE = 0,
  ROUNDED_6_6 = 1,
  ROUNDED_12_12 = 2,
  ROUNDED_28_28 = 3,
  ROUNDED_40_40 = 4,
  ROUNDED_FULL = 5,
  ROUNDED_24_RESPONSIVE = 6,
  ROUNDED_10_10 = 7,
  ROUNDED_FULL_SM = 8,
  ROUNDED_8_8 = 9
}

/**
 * Sizing Guide
 * w-10, h-10 = rendered size of ~ 40px x 40px
 * w-12, h-12 = rendered size of ~ 48px x 48px
 * w-28, h-28 = rendered size of 112px x 112px
 * w-40, h-40 = rendered size of 160px x 160px
 *
 * To prevent images from being blurry, layout
 * must be bigger than the render size, especially on
 * retina screens
 */

const USER_PHOTO_CARD_SIZES: {
  [k in AVATAR_SIZES]: {
    classNames: string;
    layout: keyof typeof IMAGE_LAYOUTS;
  };
} = {
  [AVATAR_SIZES.NONE]: {
    classNames: 'text-gray-400',
    layout: '125x125_face'
  },
  [AVATAR_SIZES.ROUNDED_6_6]: {
    classNames: 'w-6 h-6 rounded-full object-cover text-gray-400',
    layout: '40x40_face'
  },
  [AVATAR_SIZES.ROUNDED_12_12]: {
    classNames: 'w-12 h-12 rounded-full object-cover text-gray-400',
    layout: '40x40_face'
  },
  [AVATAR_SIZES.ROUNDED_28_28]: {
    classNames: 'w-28 h-28 rounded-full object-cover text-trueGray-300',
    layout: '125x125_face'
  },
  [AVATAR_SIZES.ROUNDED_40_40]: {
    classNames: 'relative w-40 h-40 rounded-full object-cover text-gray-400',
    layout: '300x300_face'
  },
  [AVATAR_SIZES.ROUNDED_FULL]: {
    classNames: 'w-full h-full rounded-full object-cover text-gray-400',
    layout: '125x125_face'
  },
  [AVATAR_SIZES.ROUNDED_FULL_SM]: {
    classNames: 'w-full h-full rounded-full object-cover text-gray-400',
    layout: '40x40_face'
  },
  [AVATAR_SIZES.ROUNDED_24_RESPONSIVE]: {
    classNames:
      'w-24 h-24 rounded-full object-cover ring-4 ring-white sm:h-32 sm:w-32 text-gray-400',
    layout: '125x125_face'
  },
  [AVATAR_SIZES.ROUNDED_10_10]: {
    classNames: 'w-10 h-10 rounded-full object-cover text-gray-400',
    layout: '40x40_face'
  },
  [AVATAR_SIZES.ROUNDED_8_8]: {
    classNames: 'w-6 h-6 rounded-full object-cover text-gray-400',
    layout: '40x40_face'
  }
};

export const UserAvatar = ({
  name,
  profile_photo_url,
  size = AVATAR_SIZES.NONE,
  className
}: {
  name?: string | null;
  size: AVATAR_SIZES;
  profile_photo_url?: string | null;
  className?: string;
}) => {
  return (
    <>
      {profile_photo_url ? (
        <img
          className={classNames(
            USER_PHOTO_CARD_SIZES[size].classNames,
            className ? className : null
          )}
          src={imageUrl(profile_photo_url, USER_PHOTO_CARD_SIZES[size].layout)}
          title={name || ''}
          alt={name || ''}
        />
      ) : (
        <ProfileDefault
          fill="#FFF"
          className={classNames(
            'border border-gray-400 bg-gray-400',
            USER_PHOTO_CARD_SIZES[size].classNames,
            className ? className : null
          )}
          title={name || ''}
        />
      )}
    </>
  );
};
