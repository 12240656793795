import { LeavePoliciesStepHeader } from '@/components/attendance/admin/wizard/LeavePoliciesStepHeader';
import {
  IFormPolicy,
  WizardStepProps
} from '@/components/attendance/admin/wizard/useLeavePoliciesWizard';
import { NewButton } from '@/components/basic/NewButton/NewButton';
import { SelectOffices } from '@/components/partial/SelectOffices';
import { SelectTeamsBeta2 } from '@/components/partial/SelectTeamsBeta2';
import { SelectUsersBeta2 } from '@/components/partial/SelectUsersBeta2';
import LoadingSpinner from '@/components/util/LoadingSpinner';
import { api } from '@/lib/api/api';
import { getObjectKeys } from '@/lib/helpers/objects/getObjectKeys';
import { LeavePolicy, WeekDayNames } from '@gettactic/api';
import { useAuthenticated } from '@gettactic/hooks';
import { useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import React, { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';

export default function LeavePolicyAssignmentStep(props: WizardStepProps) {
  const {
    userContext: { deviceTz }
  } = useAuthenticated();
  const queryClient = useQueryClient();
  const { officeId, state, closeModal } = props;
  const [loading, setLoading] = useState(false);

  const { handleSubmit, watch, control, setValue, register } =
    useForm<IFormPolicy>({
      defaultValues: { ...state.formPolicies }
    });

  const teams = watch('assignment.teams');
  const setTeams = (teams: string[]) => setValue('assignment.teams', teams);
  const offices = watch('assignment.offices');
  const setOffices = (offices: string[]) =>
    setValue('assignment.offices', offices);

  const submit = useCallback(
    async (data: IFormPolicy) => {
      setLoading(true);
      console.log('Submit data', { ...data });

      let allotted: null | number = null;
      let is_approvable = false;
      const office_days: LeavePolicy['office_days'] = {};
      let renewal: LeavePolicy['renewal'] | null = null;
      let renewal_date: LeavePolicy['renewal_date'] | null = null;
      let renewal_on_hire_date: LeavePolicy['renewal_on_hire_date'] = false;
      let hire_date_minimum_days: LeavePolicy['hire_date_minimum_days'] = null;
      const title = data.title;
      const description = data.description;
      if (data.policy_type === 'remote') {
        allotted =
          data.remote['allotted-days'] === 'limited'
            ? data.remote['allotted-days-value']
            : null;
        is_approvable = data.remote['approval'] === 'yes';
        renewal =
          data.remote['allotted-days'] === 'limited'
            ? data.remote['allotted-days-periodicity']
            : null;
        renewal_on_hire_date =
          data.remote['annual-renewal'] === 'hire-date' &&
          data.remote['allotted-days'] === 'limited' &&
          data.remote['allotted-days-periodicity'] === 'annually';
        renewal_date =
          !renewal_on_hire_date &&
          data.remote['allotted-days'] === 'limited' &&
          data.remote['allotted-days-periodicity'] === 'annually'
            ? format(data.remote['annual-renewal-value'], 'yyyy-MM-dd') +
              'T00:00:00Z'
            : null;
        hire_date_minimum_days =
          data.remote['wait-period'] === 'yes'
            ? data.remote['wait-period-value']
            : null;
      } else if (data.policy_type !== 'office') {
        hire_date_minimum_days =
          data.leaveForm['wait-period'] === 'yes'
            ? data.leaveForm['wait-period-value']
            : null;
        renewal_on_hire_date =
          data.leaveForm['annual-renewal'] === 'hire-date' &&
          data.leaveForm['allotted-days'] === 'annually';
        renewal_date =
          !renewal_on_hire_date &&
          data.leaveForm['allotted-days'] === 'annually'
            ? format(data.leaveForm['annual-renewal-value'], 'yyyy-MM-dd') +
              'T00:00:00Z'
            : null;
        renewal =
          data.leaveForm['allotted-days'] === 'annually' ? 'annually' : null;
        allotted =
          data.leaveForm['allotted-days'] === 'annually'
            ? data.leaveForm['allotted-days-value']
            : null;
        is_approvable = data.leaveForm['approval'] === 'yes';
      } else if (data.policy_type === 'office') {
        const mapValues = {
          weekly: 'weekly',
          '1': 'month_first',
          '2': 'month_second',
          '3': 'month_third',
          '4': 'month_fourth'
        };
        Object.keys(data.office.days).forEach((day) => {
          //@ts-ignore
          office_days[day] = mapValues[data.office.days[day]];
        });
      }

      const formData: Omit<LeavePolicy, 'id'> = {
        title,
        description,
        allotted,
        is_approvable,
        office_days,
        renewal,
        renewal_date,
        renewal_on_hire_date,
        hire_date_minimum_days,
        policy_type: data.policy_type,
        office_ids: data.assignment.offices,
        team_ids: data.assignment.teams,
        user_ids: data.assignment.employees,
        time_zone_id: deviceTz
      };
      console.log('Submit data', { ...data });
      console.log('data', formData);

      try {
        const res = data.id
          ? await api.client.leave.updatePolicy({ ...formData, id: data.id })
          : await api.client.leave.createPolicy(formData);
        console.log('Response is ', res);
      } catch (e) {
        toast.error("We couldn't save your policy. Please try again.");
        setLoading(false);
        return;
      }
      queryClient.invalidateQueries(['leavePolicies']);
      queryClient.invalidateQueries(['leaveOverlap']);
      setLoading(false);
      toast.success(data.id ? 'Policy updated' : 'Policy created');
      closeModal();
    },
    [officeId]
  );
  return (
    <form onSubmit={handleSubmit(submit)} className="mt-4 max-w-2xl">
      <LeavePoliciesStepHeader
        {...props}
        register={register}
        firstStep={false}
        description="Assign your policy to the relevant offices, teams and employees"
        title="Policy Assignment"
      />
      <div className="mt-5 grid grid-cols-2 place-content-between gap-4">
        <div className="">
          <label className="mb-2 block">Office assignment</label>
          <SelectOffices
            className="w-60"
            offices={offices}
            onChange={setOffices}
          />
        </div>
        <div>
          <label className="mb-2 block">Team assignment</label>
          <SelectTeamsBeta2
            className="w-60"
            includeFavs={false}
            teams={teams}
            onChange={setTeams}
          />
        </div>
      </div>
      <div className="mt-5 ">
        <label className="mb-2 block">Employee assignment</label>
        <Controller
          control={control}
          name="assignment.employees"
          render={({ field: { onChange, value } }) => (
            <SelectUsersBeta2
              onChange={(value) => {
                if (value) {
                  onChange(value);
                }
              }}
              values={value}
              className="text-trueGray-900 relative mr-9 rounded-md"
            />
          )}
        />
      </div>
      <div className="mt-10 flex justify-end">
        <NewButton size="md" type="submit" disabled={loading} className="">
          Save policy
          {loading ? <LoadingSpinner className="ml-2" /> : null}
        </NewButton>
      </div>
    </form>
  );
}
