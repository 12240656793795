type Arg =
  | false
  | boolean
  | undefined
  | string
  | null
  | Record<string, boolean>
  | Arg[];

const toVal = (arg: Arg): string => {
  if (typeof arg === 'string') return arg;
  if (typeof arg === 'boolean' || arg === null || arg === undefined) return '';
  if (Array.isArray(arg)) return arg.map(toVal).filter(Boolean).join(' ');
  if (typeof arg === 'object') {
    return Object.keys(arg)
      .filter((key) => arg[key])
      .join(' ');
  }
  return '';
};

export const classNames = (...args: Arg[]): string => {
  return args.map(toVal).filter(Boolean).join(' ');
};
